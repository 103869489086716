import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Distribute`}</h1>
    <ul>
      <li parentName="ul">{`This view is responsible for:`}
        <ul parentName="li">
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/embed-code",
              "title": "embed code"
            }}>{`embed code`}</a>{``}</li>
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/url",
              "title": "url"
            }}>{`url`}</a>{``}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      